<template>
  <div>
    <aw-section-wrapper :has-padding="false">
      <template #content="{ contentEvents: { contentLoading } }">
        <aw-tab-carousel
          :main-title="title"
          :empty-placeholder="$awt('aw.shortlist.fallback.text')"
          :tabs="tabs"
          :breakpoints="breakpoints"
          :slides="slides"
          tab-key="id"
          slide-key="id"
          :class="[$style.tabCarousel]"
          :has-title-padding="hasTitlePadding"
          @tab-opened="selectShortlistTab(contentLoading, $event.newTabItem)"
          @render-sub-slides="onRender"
        >
          <template #slide(all)(all)="{ slide }">
            <div :class="['awProductCardPlaceholder', $style.tabCarouselPlaceHolder]">
              <aw-product-card-version2
                v-if="!slide.isPlaceholder && productCardSize"
                :key="slide.id"
                :product-data="slide"
                :has-compare="false"
                :class="[$style.tabCarouselItem, $style.tabCarouselItemOffer]"
                :card-size="productCardSize"
                :opened-details="false"
                :bordered="isOnWhiteCard"
                heading-tag="h3"
              />
            </div>
          </template>
        </aw-tab-carousel>
        <div :class="$style.allProductsButtonWrapper">
          <lv-button
            styling="secondary-dark"
            shrink
            v-bind="{to: getShortlistUrl({ identifier: selectedTabId, shType: 'id' })}"
            v-on="genBannerInteractionClickEventHandlers({
              title: $awt('aw.shortlist.show_all_products')
            })"
          >
            {{ $awt('aw.shortlist.show_all_products') }}
          </lv-button>
        </div>
      </template>
    </aw-section-wrapper>
  </div>
</template>

<script>
  import { defineAsyncComponent, nextTick } from 'vue';
  import { mapState } from 'pinia';
  import { UnCachedProduct, updateProdWithUnCachedData, useProductsStore } from '~~/common/stores/products';
  import urlMixin from '~~/common/mixins/urlMixin';
  import { LvButton } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { getProductCardSizeV2 } from '~~/common/utils/productCard.js';
  import cmsPageComponentMixin from '~~/common/mixins/cmsPageComponentMixin.js';
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin.js';

  export default {
    name: 'AwShortlist',
    components: {
      LvButton,
      AwTabCarousel: defineAsyncComponent(() => import('~~/common/components/AwTabCarousel')),
      AwProductCardVersion2: defineAsyncComponent(() => import('~~/common/components/Common/AwProductCardVersion2')),
      AwSectionWrapper: defineAsyncComponent(() => import('~~/common/components/Page/Common/AwSectionWrapper')),
    },
    mixins: [
      urlMixin,
      cmsPageComponentMixin,
      analyticsBannerInteractionMixin,
    ],
    inheritAttrs: false,
    props: {
      content: {
        type: Object,
        required: true,
      },
      breakpointsOverwrite: {
        type: Array,
        default: () => ([]),
      },
      title: {
        type: String,
        default: null,
      },
      hasTitlePadding: {
        type: Boolean,
        default: true,
      },
    },
    emits: ['is-empty'],
    data () {
      return {
        slides: Object.fromEntries(this.getTabs().map(e => [e?.id, []])),
        selectedTabId: null,
        productCardSize: null,
      };
    },
    computed: {
      ...mapState(useUserInterfaceStore, {
        screenRange: state => state.mediaQueries,
      }),
      tabs () {
        return this.getTabs();
      },
      breakpoints () {
        return this.breakpointsOverwrite.length ? this.breakpointsOverwrite : [
          {
            at: this.screenRange['desktop-large-min'],
            count: 5,
          },
          {
            at: this.screenRange['desktop-small-min'],
            count: 4,
          },
          {
            at: this.screenRange['tablet-min'],
            count: 3,
          },
          {
            at: true,
            count: 'auto',
          },
        ];
      },
    },
    watch: {
      'tabs.length' (newVal) {
        if (Object.keys(this.slides).length !== newVal) {
          this.initSlides();
        }
      },
    },
    mounted () {
      this.$awEvents.addEvent('throttled-resize', this.setCardSize);
      this.initSlides();
      this.sendBannerViewInteraction();
    },
    unmounted () {
      this.$awEvents.removeEvent('throttled-resize', this.setCardSize);
    },
    methods: {
      async onRender () {
        if (!this.productCardSize) {
          await nextTick();
          await nextTick();
          if (!this.productCardSize) {
            this.setCardSize();
          }
        }
      },
      setCardSize () {
        const placeholder = (this.$el
          ?.querySelector?.('.awProductCardPlaceholder')
        );
        const clientWidth = placeholder?.clientWidth;
        const pcs = getProductCardSizeV2(clientWidth);
        if (pcs) {
          this.productCardSize = pcs;
        }
      },
      initSlides () {
        const placeholders = Array(this.breakpoints.find(b => b.at).count).fill();
        this.tabs.forEach((tab) => {
          this.slides[tab.id] = placeholders.map((u, idx) => ({
            id: idx,
            isPlaceholder: true,
          }));
        });
      },
      // show alcohol popup if shortlist is contains containsAgeRestrictedProduct flag
      async selectShortlistTab (updateLoadingState, { id }) {
        const productsStore = useProductsStore();
        this.selectedTabId = id;
        if (this.slides[id]?.length && !this.slides[id][0]?.isPlaceholder) {
          return;
        }
        updateLoadingState(true);
        const params = {
          isCached: true,
          listId: id,
          page: 1,
          itemsPerPage: 12,
          filterParams: {
            availableAndOffline: 1,
          },
        };

        if (id) {
          const {
            value: data,
            isCached,
          } = await productsStore.fetchCachedProductOrFallback({ params });
          this.slides[id] = data?.results;
          if (isCached) {
            const resp = await productsStore.getUnCachedPartsReq({ productIds: (this.slides[id] || []).map(p => p.id) });
            if (resp?.list && Object.keys(resp.list).length) {
              for (const k in resp.list) {
                resp.list[k] = new UnCachedProduct(resp.list[k]);
              }
              updateProdWithUnCachedData({
                productsToMutate: this.slides[id],
                payload: resp.list,
                $logger: this.$logger,
              });
            }
          }
        } else {
          this.slides[id] = [];
        }

        updateLoadingState(false);
        this.$emit('is-empty', this.slides[id].length === 0);
      },
      getTabs () {
        return this.content?.multipleContents?.map((item) => {
          return {
            id: item?.contentId,
            tabTitle: item?.contentTitle,
          };
        }) || [];
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">

.tabCarousel {
  --awTabItemPadding: 14px 16px;

  @include tablet(min) {
    --awTabItemPadding: 12px 16px;
  }

  &PlaceHolder {
    margin-top: 2px;
  }

  &Item {
    min-width: 160px !important; // AwProductCard override
    @include mobile(max) {
      width: 200px;
      max-width: 200px;
    }
  }
}

.allProductsButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  @include tablet(min) {
    margin-top: 40px;
  }
}
</style>
